<script setup>
    import { getOrCreateDataObject, deleteDataObject } from 'o365-dataobject';
    import { getOrCreateProcedure, Context } from 'o365-modules';
    import { alert } from 'o365-vue-services';
    import { onUnmounted, ref, reactive, computed } from 'vue';
    import { OOrgUnitsLookup, OPersonsLookup } from 'o365-system-lookups';
    import { OModal } from 'o365-ui-components';
    
    const emits = defineEmits(["newRiskID"]);
    const context = new Context();

    const procAddRisks = new getOrCreateProcedure({ id:"procAddRisks", procedureName: "astp_RiskManager_UncertaintiesAdd" });
    
    const local_dsStatusLkp = getOrCreateDataObject({
        id: 'dsStatusLkp',
        viewName: 'aviw_RiskManager_StatusesLookup',
        maxRecords: 5,
        whereClause: "Hide = 0",
        distinctRows: true,
        fields:
            [
                {name: "Name", type: "string" },
                {name: "ID", type: "int" }, 
                {name: "DefaultChoice", type: "boolean"}
            ]
    });

    const local_dsTagsLkp = getOrCreateDataObject({
        id: 'dsTagsLookup',
        viewName: 'aviw_RiskManager_AdministeredTagsLookup',
        maxRecords: 25,
        distinctRows: true,
        fields:
            [
                {name: "ID", type: "int" },
                {name: "Tag", type: "string" }, 
                {name: "OrgUnit_ID", type: "int"}
            ]
    });

    onUnmounted(() => {
        deleteDataObject(local_dsStatusLkp.id, local_dsStatusLkp.appId)
        deleteDataObject(local_dsTagsLkp.id, local_dsTagsLkp.appId)
    });

    const initialNewRiskObj = {
        name: "", 
        orgunit_id: null, 
        orgunit_name: "", 
        responsible_id: null, 
        responsible_name: "",
        status_id: null,
        status_name: "",
        type: "Threat"
    }
    const uncertainty_types = ref([
        { value: "Threat", translatedValue: $t('Threat') },
        { value: "Opportunity", translatedValue: $t('Opportunity') }
    ])
    const newRiskObj = reactive({...initialNewRiskObj})  
    const newRiskDlg = ref(null);
    let tagList = ref([]);
    const tags_whereClause = ref(null);
    
    const createNewRisk = async () => {
        if(!newRiskObj.name) {
            return alert($t("Name required"), 'warning', { autohide: true, delay: 3000})
        }
        if(!newRiskObj.orgunit_id) {
            return alert($t("OrgUnit required"), 'warning', { autohide: true, delay: 3000})
        }
        if(!newRiskObj.status_id) {
            return alert($t("Status required"), 'warning', { autohide: true, delay: 3000})
        }

        const administeredTagIDs = [];
        tagList.value.forEach((tag) => {
            administeredTagIDs.push([tag.Tag_ID]);
        });

        await procAddRisks.execute({
            OrgUnit_ID: newRiskObj.orgunit_id,
            Name: newRiskObj.name,
            Responsible_ID: newRiskObj.responsible_id,
            Status_ID: newRiskObj.status_id,
            Type: newRiskObj.type,
            AdministeredTag_Ids: (administeredTagIDs.length === 0 ? [[-16432232]] : administeredTagIDs)
        }).then(
            (response) => {
                if(response.Table[0]["ID"]) {
                    alert($t('Risk was successfully created.'), 'success', {autohide: true, delay: 3000});
                    emits("newRiskID", response.Table[0]["ID"]);
                    newRiskDlg.value.hide();
                } else {
                    alert($t('Something went wrong, try again.'), 'danger', { autohide: true, delay: 3000});
                }
            }
        );
    }

    const resetOnClose = () => {
        Object.assign(newRiskObj, initialNewRiskObj);
        tagList.value = [];
    }

    const setDefaultStatus = () => {
        local_dsStatusLkp.load().then(() => {
            const defaultStatus = local_dsStatusLkp.data.find(item => item.DefaultChoice)
            newRiskObj.status_id = defaultStatus.ID;
            newRiskObj.status_name = defaultStatus.Name;
        });
    }

    // Tags
    const computedTagsWhere = computed(() => {
        return tags_whereClause.value = `OrgUnit_ID = ${newRiskObj.orgunit_id} AND Closed IS NULL`
    })

    const addTag = (tag) => {
        const tagId = tag.ID;
        const tagName = tag.Tag;
        const tagToAdd = tagList.value.some(item => item.Tag_ID === tagId);
        if(!tagToAdd) {
            tagList.value.push({ Tag_ID: tagId, Tag_Name: tagName });
        } else {
            alert($t('Tag is already added.'), 'danger', { autohide: true, delay: 3000})
        }
    }

    const removeTag = (el) => {
        const tagId = el.srcElement.attributes.tagId.value;
        const tagToRemove = tagList.value.findIndex(item => item.Tag_ID === parseInt(tagId));
        if (tagToRemove !== -1) {
            tagList.value.splice(tagToRemove, 1);
        }
    }
    
    // Tooltip initialization
    const initializeTooltip = () => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        })
    }
</script>

<template>
    <OModal 
        name="newRiskDlg" 
        ref="newRiskDlg" 
        @shown="() => { 
            newRiskObj.orgunit_id = context.id; 
            newRiskObj.orgunit_name = context.name; 
            setDefaultStatus();  }"
        @hidden="resetOnClose"
    >
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-titel">{{ $t('Add new risk') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="col-12">
                            <label class="form-label">{{ $t('Name') }} *</label>
                            <input class="form-control" v-model="newRiskObj.name">
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <label class="form-label">{{ $t('Org. unit') }} *</label>
                                <OOrgUnitsLookup 
                                    :bind="sel => { newRiskObj.orgunit_id = sel.ID, newRiskObj.orgunit_name = sel.OrgUnit; tagList = []; }" 
                                >
                                    <template #orgunit>
                                        <input type="text" class="form-select form-control" :value="newRiskObj.orgunit_name">
                                    </template>
                                </OOrgUnitsLookup>
                            </div>
                            <div class="col">
                                <label class="form-label">
                                    {{ $t('Responsible') }} 
                                    <i  class="bi bi-question-lg" @mouseover="initializeTooltip" data-bs-toggle="tooltip" data-bs-placement="top" tabindex="0"
                                        :title="$t('If responsible is not selected, you will be set as responsible for the risk.')"
                                    ></i>
                                </label>
                                <OPersonsLookup 
                                    :bind="sel => { newRiskObj.responsible_id = sel.ID, newRiskObj.responsible_name = sel.Name }"
                                >
                                    <template #person>
                                        <input type="text" class="form-select form-control" :value="newRiskObj.responsible_name">
                                    </template>
                                </OPersonsLookup>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <label class="form-label">{{ $t('Status') }} *</label>
                                <ODataLookup
                                    class="form-select form-control" 
                                    :data-object="local_dsStatusLkp" 
                                    :bind="sel => { newRiskObj.status_id = sel.ID, newRiskObj.status_name = sel.Name }" 
                                    :value="newRiskObj.status_name"
                                    disableRecent
                                    disablePinned
                                    :noClear="true"
                                >
                                    <o-column field="Name" :headerName="$t('Name')" width="175"/>
                                </ODataLookup>
                            </div>
                            <div class="col">
                                <label class="form-label">{{ $t('Type') }}</label>
                                <select class="form-select" v-model="newRiskObj.type" @change="() => { console.log(newRiskObj.type) }">
                                    <option v-for="(type, index) in uncertainty_types" :key="index" :value="type.value" :selected="type.value === newRiskObj.type">{{ $t(type.translatedValue) }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="mt-3">
                            <ODataLookup
                                    :data-object="local_dsTagsLkp" 
                                    :bind="sel => { addTag(sel) }" 
                                    disableRecent
                                    disablePinned
                                    :noClear="true"
                                    :whereClause="computedTagsWhere"
                                    reloadOnWhereClauseChange
                                >
                                    <o-column field="Tag" :headerName="$t('Tag')" width="250"/>

                                    <template #target="{target}">
                                        <a class="pe-0" style="cursor: pointer;" :ref="target" href="#">
                                            <i class="bi bi-plus-circle" aria-hidden="true"></i> {{ $t('Add tag') }}
                                        </a>
                                    </template>
                            </ODataLookup>
                            
                            <div id="list_of_tags" class="mt-1">
                                <span v-for="(tag, index) in tagList" :key="index">
                                    <span class="badge rounded-pill bg-secondary me-1">
                                        {{ tag.Tag_Name }} <i class="bi bi-x-circle p-0" @click="(el) => { removeTag(el) }" style="cursor:pointer;" :tagId="tag.Tag_ID"></i>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-secondary" data-bs-dismiss="modal">{{ $t('Cancel') }}</button>
                    <button class="btn btn-primary" @click="createNewRisk">{{ $t('Add risk') }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>